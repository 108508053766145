<template>
	<w-form-builder ref="form" v-model="params" :fields="fields" :validation="isValid" @submit="onSubmit" @update:validation="onValidationUpdated" />
</template>

<script>
export default {
	name: 'SubscriptionForm',
	props: {
		paramsAf: {
			default: null,
			required: false,
			type: Array
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			isValid: false
		}
	},
	computed: {
		fields: function () {
			if (!this.paramsAf) {
				return []
			}
			return this.paramsAf
				.filter(field => !field.auto)
				.map(field => {
					field.label = this.$t(field.label)
					if (field.text) {
						field.text = this.$t(field.text)
					}
					return field
				})
		},
		params: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		}
	},
	methods: {
		onSubmit: function () {
			if (this.isValid) {
				this.$emit('submit')
			}
		},
		onValidationUpdated: function (isValid) {
			this.isValid = isValid
			this.$emit('update:validation', isValid)
		},
		reset: function () {
			if (this.$refs && this.$refs.form) {
				this.$refs.form.reset()
			}
		},
		resetValidation: function () {
			this.$refs.form.resetValidation()
		}
	}
}
</script>